import React, { PureComponent, useCallback, useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import styles from "../WebPages/Dashboard/dashboard.module.css";

// const data = [
//   { name: "Servers Running", value: 24 },
//   { name: "Servers Offline", value: 9 }
// ];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#fff" fontSize="25px">
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="#016bb5"
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill="#9a9da0"
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke="#fff"
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill="#fff" stroke="#fff" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey + 5}
        textAnchor={textAnchor}
        fill="#fff"
        fontSize="18px"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={20}
        textAnchor={textAnchor}
        fill="#fff"
      >
        {/* {`(Rate ${(percent * 100).toFixed()}%)`} */}
      </text>
    </g>
  );
};

export default function App({data}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
 
    <PieChart width={550} height={400} className={styles.adminDashChart} >
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx={"50%"}
        cy={"50%"}
        innerRadius={"75%"}
        outerRadius={"85%"}
        fill="#3d454c"
        stroke="#9a9da0"
        dataKey="value"
        onMouseEnter={onPieEnter}
      />
    </PieChart>

  );
}

// const renderCircleChart = (
//   <ResponsiveContainer className={styles.adminChartContainer}>
//     <PieChart className={styles.adminCircleChart} >
//       <Pie
//         activeIndex={activeIndex}
//         activeShape={renderActiveShape}
//         data={data}
//         cx={"50%"}
//         cy={"50%"}
//         innerRadius={"75%"}
//         outerRadius={"85%"}
//         fill="#3d454c"
//         stroke="#9a9da0"
//         dataKey="value"
//         onMouseEnter={onPieEnter}
//       />
//     </PieChart>
//   </ResponsiveContainer>
// );

// return (
//   <div className={styles.adminChartContainer}>
//       { renderCircleChart }
//   </div>
// );
import React, { createContext, useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { CognitoAuth } from "amazon-cognito-auth-js";
// import * as AWS from "aws-sdk";
import Pool from "./UserPool";

var authData = {
  ClientId: "4am2prse8uqojha4hmpu4v7qe9", // Your client id here
  AppWebDomain: "http://localhost:3000/",
  TokenScopesArray: ["email", "openid", "aws.cognito.signin.user.admin"], // e.g.['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'],
  RedirectUriSignIn: "http://localhost:3000/login/",
  RedirectUriSignOut: "http://localhost:3000/",
  IdentityProvider: "saic", // e.g. 'Facebook',
  UserPoolId: "us-gov-west-1_ldWyPvzw9", // Your user pool id here
  AdvancedSecurityDataCollectionFlag: false, // e.g. true
};
var auth = new CognitoAuth(authData);

const AccountContext = createContext();

const Account = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [zoneInfo, setZoneInfo] = useState("");

  const getSession = async (accessToken) => {
    if (accessToken) {
      var curUrl = window.location.href;

      auth.userhandler = {
        onSuccess: function (result) {
          //console.log("Sign in success");
          //console.log(result);
        },
        onFailure: function (err) {
          console.error("Error!");
        },
      };

      auth.parseCognitoWebResponse(curUrl);
      auth.getSession();
    }

    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (user) {
        user.getSession((err, session) => {
          if (err) {
            user.signOut();

            reject();
          }

          user.getUserAttributes((err, data) => {
            if (err) {
              user.signOut();

              reject();
            }
            console.log("Get User Attributes: ", data);

            data.map((attr) => {
              if (attr.Name === "zoneinfo") setZoneInfo(attr.Value);
              if (attr.Name === "email") {
                setEmail(attr.Value);
                let user = attr.Value.split("@")[0];
                let count = user.split(".").length;
                if (count < 3) {
                  setLastName(
                    attr.Value.split(".")[1].charAt(0).toUpperCase() +
                      attr.Value.split(".")[1].split("@")[0].slice(1)
                  );
                } else {
                  setLastName(
                    attr.Value.split(".")[2].charAt(0).toUpperCase() +
                      attr.Value.split(".")[2].split("@")[0].slice(1)
                  );
                }
                setFirstName(
                  attr.Value.split(".")[0].charAt(0).toUpperCase() +
                    attr.Value.split(".")[0].slice(1)
                );
              }
            });
            resolve({ session: session, data: data });
          });
        });
      } else {
        reject();
      }
    });
  };

  const getUserName = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (user) {
        user.getUserAttributes((err, data) => {
          if (err) {
            //console.log(err);
            reject(err);
          }

          data.map((attr) => {
            if (attr.Name === "identities") {
              if (JSON.parse(attr.Value)[0]) {
                if (JSON.parse(attr.Value)[0]["userId"]) {
                  resolve(JSON.parse(attr.Value)[0]["userId"]);
                }
              }
            }
          });
        });
      } else {
        reject();
      }
    });
  };

  const authenticate = async (Username, Password, setAuthenticated) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          //console.log("onSuccess: ", data);
          setAuthenticated(true);
          resolve(data);
        },
        onFailure: (err) => {
          //console.error("onFailure :", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          //console.log("newPasswordRequired: ", data);
          resolve(data);
        },
      });
    });
  };

  const logout = (setAuthenticated) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      setAuthenticated(false);
    }
  };

  const accessToken = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      console.log("getSession user data: ", user);
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          }
          resolve(session.getIdToken().getJwtToken());
        });
      } else {
        reject();
      }
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        getUserName,
        accessToken,
        firstName,
        lastName,
        email,
        zoneInfo,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };

import { useEffect, useState, useContext } from "react";
import styles from "./Server.module.css";
import React from "react";
import { AiOutlineDelete, AiOutlineSave, AiOutlineEdit } from "react-icons/ai";
import { AccountContext } from "../../Authentication/Account";
import {
  getEC2Instance,
  addNote,
  deleteNote,
  updateDNS,
} from "../../../services/ReadyOneAdminAPI";

export default function Server({ server, activeShow, deactivateShow }) {
  const [data, setData] = useState({});
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState({});
  const [dynamicDNS, setDynamicDNS] = useState("");
  const [dnsSave, setDnsSave] = useState(false);
  const { firstName, lastName } = useContext(AccountContext);

  useEffect(() => {
    setData(server);
    //setData(server);
    setNotes(server.notes || []);
    setDynamicDNS(server.DynamicDNS || "No domain");
  }, []);

  useEffect(() => {
    if(activeShow === data.InstanceId) document.body.style.overflow = 'hidden !important';
  }, [])

  useEffect(() => {
    let comp = [server.DynamicDNS, "No domain"];
    comp.includes(dynamicDNS) ? setDnsSave(false) : setDnsSave(true);
  }, [dynamicDNS]);

  const addNewNote = () => {
    addNote(data.InstanceId, {
      note: newNote,
      author: `${firstName} ${lastName}`,
      date: new Date().toISOString(),
    });

    setNotes((notes) => [
      ...notes,
      {
        note: newNote,
        author: `${firstName} ${lastName}`,
        date: new Date().toISOString(),
      },
    ]);
    console.log(newNote);
  };

  const deleteServerNote = (note) => {
    console.log(dynamicDNS);
    deleteNote(data.InstanceId, note).then((result) => {
      getEC2Instance(data.InstanceId).then((result) => {
        setData(result);
        setNotes(result.notes);
        setDynamicDNS(server.DynamicDNS || server.DomainName || "No domain");
      });
    });
  };

  const saveDNS = () => {
    updateDNS(data.InstanceId, dynamicDNS).then(async (result) => {
      setData({
        ...data,
        DynamicDNS: dynamicDNS,
      });

      setDnsSave(false);
    });
  };

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

  return (
    <div
      className={`${styles.modal} ${
        activeShow === data.InstanceId ? styles.visible : styles.hidden
      }`}
      onClick={() => deactivateShow()}
    >
      <div
        className={`${styles.requestMain} ${styles.modalContent} ${
          activeShow === data.InstanceId && styles.visibleContent
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.requestMain}>
          <h1 className={styles.requestTitle}>{data.Name}</h1>
          <div className={styles.requestRow}>
            <div className={styles.requestColLeft}>
              <div className={styles.infoContainer}>
                <div className={styles.infoSection}>
                  <span className={styles.serverHeading}>Server Info</span>
                  {data.Stack && (
                    <span className={styles.serverInfo}>
                      <b>Stack:</b> {data.Stack}
                    </span>
                  )}
                  <span className={styles.serverInfo}>
                    <b>State:</b> {data.State}
                  </span>
                  <span className={styles.serverInfo}>
                    <b>Platform:</b> {data.PlatformDetails}
                  </span>
                  <span className={styles.serverInfo}>
                    <b>Key:</b> {data.KeyName}
                  </span>
                  <span className={styles.serverInfo}>
                    <b>Instance Type:</b> {data.InstanceType}
                  </span>
                </div>
                <div className={styles.infoSection}>
                  <span className={styles.serverHeading}>Network Info</span>
                  <span className={styles.serverInfo}>
                    <b>Domain Name:</b> {data.DomainName}
                  </span>
                  <span className={styles.serverInfo}>
                    <b>Public IP:</b> {data.PublicIpAddress}
                  </span>
                  <span className={styles.serverInfo}>
                    <b>Private IP:</b> {data.PrivateIpAddress}
                  </span>
                  <span className={`${styles.serverInfo} ${styles.dynamicDNS}`}>
                    <b>Dynamic DNS:</b>
                    <div className={styles.dnsHolder}>
                      <input
                        className={styles.DNSInput}
                        onChange={(e) => setDynamicDNS(e.target.value)}
                        type="text"
                        value={dynamicDNS}
                      />
                      {dnsSave ? (
                        <AiOutlineSave
                          size="1rem"
                          className={styles.DNSSave}
                          onClick={() => saveDNS()}
                        />
                      ) : (
                        <AiOutlineEdit size="1rem" />
                      )}
                    </div>
                  </span>
                </div>
                <div className={styles.break}></div>
                {Number.isFinite(parseFloat(data.MonthlyCost)) && (
                  <div className={styles.pricingSection}>
                    <span className={styles.pricingHeading}>Pricing Info</span>
                    <span className={styles.pricingInfo}>
                      <b>Stack Cost:</b>{" "}
                      {"$" +
                        (Math.round(data.MonthlyCost * 100) / 100).toFixed(2)}
                      /Month
                    </span>
                  </div>
                )}
              </div>
              <div className={styles.requestColRight}>
                <div className={styles.actionBtnHolder}>
                  <button className={styles.actionBtn}>Restart</button>
                  <button className={styles.actionBtn}>Power Off</button>
                  <button className={styles.actionBtn}>Disable Network</button>
                  <button className={styles.actionBtn}>Whitelist Me</button>
                </div>
              </div>
              <div className={styles.notesSection}>
                <span className={styles.serverHeading}>Notes</span>
                {notes ? (
                  <div className={styles.notesHolder}>
                    {notes.map((note) => (
                      <div key={note.date} className={styles.note}>
                        <div className={styles.noteHeader}>
                          <div className={styles.noteAuthor}>{note.author}</div>
                          <div className={styles.noteDate}>{note.date}</div>
                          <AiOutlineDelete
                            className={styles.deleteButton}
                            size="2rem"
                            onClick={() => deleteServerNote(note)}
                          />
                        </div>
                        <div className={styles.noteText}>{note.note}</div>
                      </div>
                    ))}
                    <div key="new-note" className={styles.note}>
                      <div className={styles.noteHeader}>
                        <div
                          className={styles.noteAuthor}
                        >{`${firstName} ${lastName}`}</div>
                        <div className={styles.noteDate}>
                          {new Date().toISOString()}
                        </div>
                      </div>
                      <div className={styles.noteText}>
                        <textarea
                          className={styles.notesInput}
                          onChange={(e) => setNewNote(e.target.value)}
                        ></textarea>
                      </div>
                      <div className={styles.notesBtnHolder}>
                        <button
                          className={styles.notesSubmit}
                          onClick={() => addNewNote()}
                        >
                          Submit Note
                        </button>
                        <button className={styles.notesSubmit}>Clear</button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span className={styles.serverInfo}>No Notes</span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.requestFooter}></div>
        </div>
      </div>
    </div>
  );
}

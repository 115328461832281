import React, { useEffect, useState } from "react";
import { BsChevronRight, BsSun } from "react-icons/bs";
import styles from "./Requests.module.css";
import RequestView from "./RequestView";

const RequestTable = ({ data, filter, label }) => {
  const [tableData, setTableData] = useState(data.filter((d) => d.request.requestType.includes(filter)))
  const [activeShow, setActiveShow] = useState('');

  useEffect(() => {
    setTableData(data.filter((d) => d.request.requestType.includes(filter)));
  },[data])

  const formatDate = (input) => {
    let date = new Date(input);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return `${month}.${dt}.${year}`;
  };

  const getDaysSinceDate = (date) => {
    date = new Date(date);
    var Difference_In_Time = new Date().getTime() - date.getTime();
    // To calculate the no. of days between two dates
    return Math.floor(Difference_In_Time / (1000 * 3600 * 24));
  };
  console.log(`Data Length: ${data.length}`);
  const parseFilter = (name) => {
    let re = new RegExp(`\\b${label}\\b`, 'gi')
    return name.replace(re, "")
  }

  return (
    <>
      
        {tableData.length > 0 ? (
          <div className={styles.requestTableCont}>
          <table className={styles.requestTable}>
            <thead className={styles.requestThead}>
              <tr>
                <th className={styles.requestTh}>Request Name</th>
                <th className={styles.requestTh}>Status</th>
                <th className={styles.requestTh}>Last Updated</th>
                <th className={styles.requestTh}>Days Open</th>
                <th className={styles.requestTh}>Requester</th>
              </tr>
            </thead>
            <tbody className={styles.requestTbody}>
              {tableData.sort((a,b) => getDaysSinceDate(b.request.requestDate) - getDaysSinceDate(a.request.requestDate))
                .map((r) => (
                  // <Link href={`/requests/${r.id}`} key={r.id} passHref>
                  <>
                    <tr className={styles.requestTr} key={r.id} onClick={() => setActiveShow(r.id)}>
                      <td className={styles.requestTdOne}>{parseFilter(r.request.requestName)}</td>
                      <td className={styles.requestTd}>{r.request.status}</td>
                      <td className={styles.requestTd}>{formatDate(r.request.lastUpdateDate)}</td>
                      <td className={styles.requestTd}>{getDaysSinceDate(r.request.requestDate)}</td>
                      <td className={styles.requestTd}>{r.request.requester}</td>
                      
                    </tr>
                    </>
                  // </Link>
                ))}
            </tbody>
          </table>
            {data.map((r) => (
              <RequestView
                data={r}
                activeShow={activeShow}
                deactivateShow={() => setActiveShow("")}
              />
            ))}
          </div>
        ) : (
          <div className={styles.sunCaughtUp}>
            You're all caught up
            <BsSun className={styles.theSunIsHot} />
          </div>
        )}
      
    </>
  );
};

export default RequestTable;

import { useEffect, useState, useContext } from "react";
import styles from "./Feedback.module.css";
import commentStyles from "../../../styles/Comments.module.css";
import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import { AccountContext } from "../../Authentication/Account";
import CommentHolder from "../../CommentHolder/CommentHolder";
import { updateRequest } from "../../../services/ReadyOneAdminAPI";
import RingLoader from "react-spinners/RingLoader";
import { AiFillCheckCircle } from "react-icons/ai";

export default function FeedbackView({ feedback, activeShow, deactivateShow }) {
  const { firstName, lastName } = useContext(AccountContext);
  const [request, setRequest] = useState({});
  const [requestId, setRequestId] = useState("");
  const [comment, setComment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    console.log(feedback);
  }, []);

  return (
    <div
      className={`${styles.modal} ${
        activeShow === feedback.feedbackID ? styles.visible : styles.hidden
      }`}
      onClick={() => deactivateShow()}
    >
      <div
        className={`${styles.requestMain} ${styles.modalContent} ${
          activeShow === feedback.feedbackID && styles.visibleContent
        }`}
        onClick={(e) => e.stopPropagation()}
      >
          <div className={styles.feedback}>
              <span className={styles.feedbackHeading}>{feedback.feedback.topic.label}</span>
              <span className={styles.feedbackContent}><b>Subject:</b> {feedback.feedback.subject}</span>
              <span className={styles.feedbackContent}><b>Explanation:</b> {feedback.feedback.explanation}</span>
              <span className={styles.feedbackContent}><b>Submitter:</b> {feedback.feedback.email}</span>
          </div>
        
      </div>
    </div>
  );
}

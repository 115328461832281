const apiURL = "https://api.admin.readyone.net";
const apiKey = "QTONptmuas7y9K7bnenki6Qs7mClbJfb2iPC9Ruh";

export function updateRequest(request) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  request = {
    ...request,
    request: JSON.stringify({
      ...request.request,
      requestHTML: Buffer.from(request.request.requestHTML).toString("base64"),
    }),
  };

  var raw = JSON.stringify(request);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/update/request`, requestOptions).then((response) =>
    response.json()
  );
}

export function getEC2Instance(id) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get/ec2-instance?id=${id}`, requestOptions)
    .then((response) => response.json())
    .then((response) => response.Items[0])
    .catch((error) => console.log("error", error));
}

export function addNote(id, note) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ id, note });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(`${apiURL}/add/server-note`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}

export function deleteNote(id, note) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ id, note });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/delete/server-note`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}

export function updateDNS(id, DynamicDNS) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ id, DynamicDNS });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/update/dynamic-dns`, requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}

export function getMonthlyCosts() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get/monthly-costs`, requestOptions)
    .then((response) => response.json())
    .then((result) => result.body)
    .catch((error) => console.log("error", error));
}

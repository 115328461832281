import React, { useState, useContext, useEffect, Component } from "react";
import "./Login.css";
import { Button, Form, Col, Modal, Row } from "react-bootstrap";
import { AccountContext } from "./Account";
import { useLocation, Navigate } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import ImageHelper from "../ImageHelper";
import readyone from "./images/readyone.png";
import saiclogo from "./images/saiclogo.png";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [adminCheck, setAdminCheck] = useState(true);

  const { authenticate, getSession } =
    useContext(AccountContext);
  const search = useLocation().hash;

  const accessToken = new URLSearchParams(search).get("#access_token") || "";
  useEffect(() => {
    setLoading(true);

    getSession(accessToken)
      .then((result) => {
        console.log("Session: ", result.session);

        console.log("FRom Loging user effect:", result.data);
        let temp = "";
        result.data.map((attr) => {
          if (attr.Name === "email") props.setEmail(attr.Value);
          if (attr.Name === "zoneinfo") temp = attr.Value;
        });
        console.log(temp)
        if(temp === "administrator") {
          props.setAuthenticated(true)
          setIsAuth(true);
        }  else {
          props.setAuthenticated(false);
          if(accessToken) alert("You do not have permissions to view this page")
          
        } 
        setLoading(false);
        
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const onSubmit = (event) => {
    authenticate(email, password, props.setAuthenticated)
      .then((data) => {
        //console.log("Logged In! ", data);
        setFailed(false);
        setIsAuth(true);
      })
      .catch((err) => {
        //console.error("Failed to login!: ", err);
        setFailed(true);
      });
  };

  const openLogin = () => {
    console.log(window.location.hostname);
    switch(window.location.hostname) {
      case("dev.admin.readyone.net"):
        window.location.href = "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://dev.admin.readyone.net/";
        break;
      case("admin.readyone.net"):
        window.location.href = "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://admin.readyone.net/";
        break;
      case("localhost"):
        window.location.href = "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=http://localhost:3000/"
        break;
      default:
        window.location.href = "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://admin.readyone.net/";
        break;

    }
  }

  return loading ? (
    <div className="login-ring-loader">
      <RingLoader size={80} color={"#ffffff"} loading={loading} size="200px" />
    </div>
  ) : isAuth ? (
    <Navigate to="/Dashboard" />
  ) : (
    <div className="login-whole-page">
      <Modal className="login-modal" show={true} centered>

        <Modal.Header className="login-modal-logo">
          <img className="loginSAIClogo" src={saiclogo} />
        </Modal.Header>

        <Modal.Header className="login-modal-ro">
          <img className="loginROlogo" src={readyone} />
        </Modal.Header>

        <Modal.Header className="login-modal-header">
          <Modal.Title>Please login to continue.</Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body text-center">
          <br />
          <Form.Group as={Col}>
            <Form.Label className="login-text-white">Email</Form.Label>
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              as="input"
              className="my-1 mr-sm-2"
            ></Form.Control>
          </Form.Group>
          <br />
          <Form.Group as={Col}>
            <Form.Label className="login-text-white">Password</Form.Label>
            <Form.Control
              onChange={(e) => setPassword(e.target.value)}
              as="input"
              type="password"
              className="my-1 mr-sm-2"
            ></Form.Control>
          </Form.Group>
          <br />
        </Modal.Body>

        <Modal.Footer className="login-modal-footer">
          <Button
            variant="danger"
            className="login-modal-button"
            disabled={!validateForm()}
            onClick={() => onSubmit()}
          >
            Login
          </Button>
          <br />
          <br />
          <Button
            variant="danger"
            className="login-modal-button"
            onClick={() => {
              
              openLogin()
            }}
          >
            SAIC SSO Login
          </Button>
        </Modal.Footer>

        <Modal.Footer className="login-modal-footer-disc">
        <Button className="disc-button" onClick={() => setShow(!show)}>
            <div className="login-disc-text" id="disclaimer">
              By accessing this site, you are agreeing to SAIC's Acceptable Use Policy.
              <br />
              Click to learn more.

              {show?
              <div className="policy-button">
                This computer system is the property of SAIC and includes all related equipment, networks, and network devices. 
                This computer system may contain US government information and is only for use by authorized users for business purposes. 
                There is no right of privacy in this computer system. Use constitutes consent and acknowledgement that the computer system, 
                and any information placed or sent over the computer system, is monitored, recorded and subject to audit and collection for 
                all lawful purposes including in relation to investigations and/or legal proceedings. Any unauthorized use, accessing of or 
                attempt to access this computer system, or any unauthorized alteration, damage, removing, addition, or destruction of any data, 
                program or software in this computer system is subject to criminal and civil prosecution under state and/or federal law. 
                Unauthorized use may result in the delivery of possible evidence collected during monitoring to law enforcement officials. 
                Failure to comply with this notice may be grounds for disciplinary action, up to and including termination and/or civil and criminal prosecution.
                <br />
              </div>
              :null}

            </div>
        </Button>
        </Modal.Footer>

      </Modal>
    </div>
  );
};

export default Login;
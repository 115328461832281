import React, { PureComponent, useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styles from "../WebPages/Dashboard/dashboard.module.css";
import { BsCurrencyDollar } from "react-icons/bs";

const SimpleLineChart = ({ data }) => {
  console.log(data);
  const CustomTooltip = ({ active, payload, label, cursor }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className={styles.tooltipLabel}>{`USD  ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const pseudoLog = (x) => {
    return Math.asinh(x / 2) / Math.log(10);
  };

  const renderLineChart = (
    <LineChart
      width={525}
      height={400}
      data={data}
      margin={{ top: 20, right: 15, bottom: 10, left: 5 }}
      scale={pseudoLog}
    >
      <CartesianGrid stroke="rgb(102, 102, 102)" strokeDasharray="0 0" />
      <XAxis stroke="#ccc" dataKey="name" />
      <YAxis
        stroke="#ccc"
        dataKey="USD"
        type="number"
        domain={["auto", "auto"]}
      />
      <Tooltip
        cursor={{ fill: "rgba(154, 157, 160, 0.15)" }}
        Tooltip
        content={<CustomTooltip />}
        allowEscapeViewBox={{ x: false, y: true }}
        position={{ y: 0 }}
      />
      <Line
        type="monotone"
        dataKey="USD"
        stroke="#82ca9d"
        activeDot={{ r: 8 }}
      />
    </LineChart>
  );

  return <div className={styles.adminDashChart}>{renderLineChart}</div>;
};

export default SimpleLineChart;

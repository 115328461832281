const apiURL = "https://api.readyone.net";
const apiKey = "byGTHF1wmJ1nxf7eMp7fNytA8M9PWwLsIU85or90";

export function getHowTo_old() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/gethowto`, requestOptions)
    .then((response) => response.json())
    .then((body) => body.body);
}

export function getTable(tableName) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ tableName: tableName });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/getTable`, requestOptions).then((response) =>
    response.json()
  );
}

export function sendEmail(Data) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(Data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/sendEmail`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function getHowTo() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  // myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/gets3structure`, requestOptions).then((response) => response.json()).then((body) => body.body);
}

export function search(searchTerm) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${apiURL}/search?q=${searchTerm}`, requestOptions)
    .then(response => response.json())
    .then(result => result.hits.hits)
    .catch(error => console.log('error', error));
}

export function searchWorkItems(searchTerm) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${apiURL}/search/work-items?q=${searchTerm}`, requestOptions)
    .then(response => response.json())
    .then(result => result.hits.hits)
    .catch(error => console.log('error', error));
}
import React from 'react';
import { Alert } from 'react-bootstrap';


const EmptyTableAlert = (props) => {

    return (
        <Alert variant="secondary" className="empty-alert">
        No active Stacks. Create a Stack to see it`&apos;`s progress here.
        </Alert>
    );
  };
  
  export default EmptyTableAlert;
  
import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from "../WebPages/Dashboard/dashboard.module.css";
import { BsPersonPlus } from "react-icons/bs";

const SimpleBarChart = () =>{
const data = [
  {
    Group: 'New User',
    New: 8
  },
  {
    Group: 'TWC',
    New: 5
  },
  {
    Group: 'IBM ELM',
    New: 9
  },
  {
    Group: 'Innovator',
    New: 6
  }
];

const CustomTooltip = ({ active, payload, label, cursor }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className={styles.tooltipLabel}><BsPersonPlus className={styles.tooltipIcon}/>{`  ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};


const renderBarChart = (
        <BarChart
          width={525}
          height={400}
          data={data}
          barSize={50}
          margin={{
            top: 15,
            right: 15,
            left: 0,
            bottom: 10,
          }}
        >
                  <defs>
          <linearGradient id="colorBars" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor="#016bb5" stopOpacity={1}/>
            <stop offset="80%" stopColor="#016bb5" stopOpacity={0.25}/>
          </linearGradient>
        </defs>
          <CartesianGrid  stroke="rgb(102, 102, 102)" strokeDasharray="0 0" />
          <XAxis stroke="#ccc" dataKey="Group" />
          <YAxis stroke="#ccc" />
          <Tooltip cursor={{ fill: 'rgba(154, 157, 160, 0.15)'}} Tooltip content={<CustomTooltip />} allowEscapeViewBox={{ x: false, y: true }} position={{ y:0 }} />
          <Bar dataKey="New" fill="url(#colorBars)" />
        </BarChart>
    );

    return (
        <div className={styles.adminDashChart} >
            { renderBarChart }
        </div>
      );
}

export default SimpleBarChart;

import React from "react";

const iconObj = {
  copy: "/copy.png",
  refresh: "/refresh.png",
  delete: "/delete.png",
  restart: "/restart.png",
  saic: "/saic.png",
  mock: "/saiccopy.png",
  twclogo: "/TeamworkCloudLogo.png",
  newuser: "/newuser.png",
  ibm: "/ibm.png",
  download: "/download.png",
  saiclogo: "/saiclogo.png",
  aras: "/aras.png",
  ewm: "/ewm.png",
  rhapsody: "/rhapsody.png",
  solidworks: "/solidworks.png",
  cameosp3: "/sp3.png",
  cameosp4: "/sp4.png",
  eif: "/eifstack.png",
  eiflong: "/eiflong.png",
  readyone: "/readyone.png",
  defaultapp: "/defaultapp.png",
  modelcenter: "/modelcenter.png",
  tomsawyer: "/tomsawyer.png",
  elm: "/ibm.png",
  agistk: "/stk.png",
  word: "/word.png",
  excel: "/excel.png",
  powerpoint: "/powerpoint.png",
  ToolFederation: "/toolfedteam.png",
  DARE: "/dareteam.png",
  DEecosystem: "/deecoteam.png"
};

const ImageHelper = ({width, mode, onClick}) => {
  
    return (
      <img
        style={{
          width: width,
          justifyContent: "center",
        }}
        alt={mode}
        src={iconObj[mode] || iconObj["defaultapp"]}
        onClick={() => onClick()}
      />
    );
}

ImageHelper.defaultProps = {
  width: "1.5rem",
  color: "gainsboro",
};

export default ImageHelper;

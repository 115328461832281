export function getVPCIds() {
    var myHeaders = new Headers();
    myHeaders.append("X-Api-Key", "cHclcdFlsX4yHJsYUSfKd4ypNvALSBDi1sLyKW8O");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    return fetch("https://1bu1gkixh3.execute-api.us-gov-west-1.amazonaws.com/default/getVPCIds", requestOptions).then(response => response.json())
}
import React, { useState, useContext, useEffect } from "react";
import styles from "../../styles/Comments.module.css";
import { AccountContext } from "../Authentication/Account";
import { AiOutlineDelete, AiOutlineSave, AiOutlineEdit } from "react-icons/ai";
import { deleteComment, getRequest } from "../../services/ReadyOneAdminAPI";

import formatDate from "../../services/formatDate";

const CommentHolder = ({ request, addComment, setComment, comment }) => {
  const [comments, setComments] = useState(request?.comments || []);
  const { firstName, lastName } = useContext(AccountContext);

  useEffect(() => {
    setComments(request?.comments || [])
  }, [request])

  //   const deleteExistingComment = (comment) => {
  //     deleteComment(requestId, comment).then((result) => {
  //       getRequest(requestId).then((result) => {
  //         setComments(result.comments);
  //       });
  //     });
  //   };

  return (
    <div className={styles.commentHolder}>
      <div key="new-comment" className={styles.comment}>
        <div className={styles.commentHeader}>
          <div
            className={styles.commentAuthor}
          >{`${firstName} ${lastName}`}</div>
          <div className={styles.commentDate}>
            {formatDate(new Date().toISOString())}
          </div>
        </div>
        <div className={styles.commentText}>
          <textarea
            className={styles.commentInput}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
        <div className={styles.commentBtnHolder}>
          <btn className={styles.commentSubmit} onClick={() => addComment()}>
            Submit Comment
          </btn>
          <btn className={styles.commentSubmit}>Clear</btn>
        </div>
      </div>
      {comments.sort((a, b) => new Date(b.commentdate) - new Date(a.commentdate)).map((comment) => (
        <div key={comment.date} className={styles.comment}>
          <div className={styles.commentHeader}>
            <div className={styles.commentAuthor}>{comment.author}</div>
            <div className={styles.commentDate}>
              {formatDate(comment.commentdate)}
            </div>
            <AiOutlineDelete
              className={styles.deleteButton}
              size="2rem"
              // onClick={() => deleteExistingComment(comment)}
            />
          </div>
          <div className={styles.commentText}>{comment.comment}</div>
        </div>
      ))}
    </div>
  );
};

export default CommentHolder;

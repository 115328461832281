import { useEffect, useState, useContext } from "react";
import styles from "./Requests.module.css";
import commentStyles from "../../../styles/Comments.module.css";
import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import { AccountContext } from "../../Authentication/Account";
import CommentHolder from "../../CommentHolder/CommentHolder";
import { updateRequest } from "../../../services/ReadyOneAdminAPI";
import RingLoader from "react-spinners/RingLoader";
import { AiFillCheckCircle } from "react-icons/ai";

export default function RequestView({ data, activeShow, deactivateShow }) {
  const { firstName, lastName } = useContext(AccountContext);
  const [request, setRequest] = useState({});
  const [requestId, setRequestId] = useState("");
  const [comment, setComment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [updating, setUpdating] = useState(false);

  let assignees = [
    "Hance Washington",
    "Kilpatrick Jones",
    "Drew Michel",
    "Jakob Brooks",
    "Calvin Coker",
    "Andrew Fischer",
    "Not Assigned",
  ];
  let statuses = ["New", "In Progress", "Done"];

  useEffect(() => {
    console.log(data);
    setRequestId(data.id);
    setRequest(data.request);
    setSelectedStatus({
      label: data.request.status,
      value: data.request.status,
    });
    setSelectedAssignee({
      label: data.request.assignee || "Unassigned",
      value: data.request.assignee || "Unassigned",
    });
  }, []);

  useEffect(() => {
    if (
      requestId !== "" &&
      (selectedStatus.value !== data.request.status ||
        selectedAssignee.value !== data.request.assignee)
    ) {
      let newRequest = {
        ...request,
        status: selectedStatus.value,
        assignee: selectedAssignee.value,
      };
      setRequest(newRequest);
      setUpdating(true);
      updateRequest({ id: requestId, request: newRequest }).then((data) => {
        console.log(data);
        setUpdating(false);
      });
      console.log(request);
    }
  }, [selectedStatus, selectedAssignee]);

  const addComment = () => {
    let newRequest = {
      ...request,
      comments: [
        ...request.comments,
        {
          comment: comment,
          author: `${firstName} ${lastName}`,
          commentdate: new Date().toISOString(),
        },
      ],
    };
    console.log(newRequest);
    setRequest(newRequest);
    setUpdating(true);
    updateRequest({ id: requestId, request: newRequest }).then((data) => {
      console.log(data);
      setUpdating(false);
      setComment("");
    });
    
  };

  return (
    <div
      className={`${styles.modal} ${
        activeShow === requestId ? styles.visible : styles.hidden
      }`}
      onClick={() => deactivateShow()}
    >
      <div
        className={`${styles.requestMain} ${styles.modalContent} ${
          activeShow === requestId && styles.visibleContent
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.title}>
          <div className={styles.requestTitle}>User Request</div>
          <div className={styles.updateIndicator}>
            {updating ? (
              <RingLoader size={"40px"} color={"#ffffff"} />
            ) : (
              <AiFillCheckCircle />
            )}
          </div>
        </div>

        <Row className={styles.requestRow}>
          <Col className={styles.requestColLeft}>
            <div className={styles.requestBody}>
              <div>
                <div
                  className={styles.requestData}
                  dangerouslySetInnerHTML={{
                    __html: request.requestHTML,
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className={styles.requestColRight}>
            <div>
              <div>
                <p className={styles.requestText}>Status:</p>
                <Select
                  className={styles.requestDropdown}
                  options={statuses.map((status) => ({
                    label: status,
                    value: status,
                  }))}
                  isMulti={false}
                  value={selectedStatus}
                  onChange={(event) => {
                    setSelectedStatus(event);
                  }}
                />
              </div>
              <div>
                <p className={styles.requestText}>Assignee:</p>
                <Select
                  className={styles.requestDropdown}
                  options={assignees.map((assignee) => ({
                    label: assignee,
                    value: assignee,
                  }))}
                  isMulti={false}
                  value={selectedAssignee}
                  onChange={(event) => {
                    setSelectedAssignee(event);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className={styles.requestFooter}>
          <CommentHolder
            request={request}
            addComment={addComment}
            setComment={setComment}
            comment={comment}
          />
        </div>
      </div>
    </div>
  );
}

import styles from "./Feedback.module.css";
import React, { useEffect, useState } from "react";
import FeedbackTable from "./FeedbackTable";
import RingLoader from "react-spinners/RingLoader";
import { getTable } from "../../../services/ReadyOneAPI";

export default function Feedback({ setPath }) {
  const [help, setHelp] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [bug, setBug] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPath(window.location.pathname);
    setLoading(true);
    getTable("ReadyOneFeedback").then((data) => {
      data = data.body.Items;
      setHelp(
        data.filter((r) => {
          return r.feedback.topic.value === "help";
        })
      );
      setSuggestion(
        data.filter((r) => {
          return r.feedback.topic.value === "suggestion";
        })
      );
      setFeedback(
        data.filter((r) => {
          return r.feedback.topic.value === "feedback";
        })
      );
      setBug(
        data.filter((r) => {
          return r.feedback.topic.value === "bug";
        })
      );
      setLoading(false);
    });
  }, []);

  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index) =>
    activeIndex === index ? styles.panel : styles.hidden;
  const checkActiveTab = (index) =>
    activeIndex === index ? styles.activeTab : "";

  return loading ? (
    <div className="login-ring-loader">
      <RingLoader size={80} color={"#ffffff"} loading={loading} width="200px" />
    </div>
  ) : (
    <div className={styles.main}>
      <div className={styles.titleUserRequests}>Feedback</div>

      <div className={styles.tabsUserRequests}>
        <div className={styles.fbTabContainer}>
          <button
            className={`tab ${checkActiveTab(1)}`}
            onClick={() => handleClick(1)}
          >
            Help
          </button>

          <button
            className={`tab ${checkActiveTab(2)}`}
            onClick={() => handleClick(2)}
          >
            Feedback
          </button>

          <button
            className={`tab ${checkActiveTab(3)}`}
            onClick={() => handleClick(3)}
          >
            Suggestion
          </button>

          <button
            className={`tab ${checkActiveTab(4)}`}
            onClick={() => handleClick(4)}
          >
            Bug
          </button>
        </div>

        <div className={styles.panels}>
          <div className={`${checkActive(1)}`}>
            <div className={styles.fbCardCont}>
              <div className={styles.fbStatsCard}>
                <div className={styles.fbStatsHeader}>Help</div>
                <div className={styles.fbStatistics}>
                  <FeedbackTable data={help} />
                </div>
              </div>
            </div>
          </div>

          <div className={`${checkActive(2)}`}>
            <div className={styles.fbCardCont}>
              <div className={styles.fbStatsCard}>
                <div className={styles.fbStatsHeader}>Feedback</div>
                <div className={styles.fbStatistics}>
                  <FeedbackTable data={feedback} />
                </div>
              </div>
            </div>
          </div>

          <div className={`${checkActive(3)}`}>
            <div className={styles.fbCardCont}>
              <div className={styles.fbStatsCard}>
                <div className={styles.fbStatsHeader}>Suggestion</div>
                <div className={styles.fbStatistics}>
                  <FeedbackTable data={suggestion} />
                </div>
              </div>
            </div>
          </div>

          <div className={`${checkActive(4)}`}>
            <div className={styles.fbCardCont}>
              <div className={styles.fbStatsCard}>
                <div className={styles.fbStatsHeader}>Bug</div>
                <div className={styles.fbStatistics}>
                  <FeedbackTable data={bug} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function deleteStack(stackName) {
    console.log(stackName);
    let raw = "{ \"stackName\": \""+ stackName + "\"}";
    console.log(raw);
    var myHeaders = new Headers();
    myHeaders.append("X-Api-Key", "usCxXaOcGx8pf4UZTlrEY9dmI7gJ7ULs1RdGxcrq");

    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    return fetch('https://1bu1gkixh3.execute-api.us-gov-west-1.amazonaws.com/default/deleteStack', requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
}
import "./App.css";
import ReadyOneNavbar from "./components/Navbar/ReadyOneNavbar";
import FooterBar from "./components/Navbar/FooterBar";
import React, { useState, useEffect, useContext } from "react";
import ParticlesB from "./components/AnimatedComponents/Particles";
import Login from "./components/Authentication/Login";
import { AccountContext } from "./components/Authentication/Account";
import Dashboard from "./components/WebPages/Dashboard/Dashboard";
import Requests from "./components/WebPages/Requests/Requests";
import Servers from "./components/WebPages/Servers/Servers";
import Feedback from "./components/WebPages/Feedback/Feedback";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  const [dev, setDev] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [permissions, setPermissions] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [path, setPath] = useState("/");

  const { authenticate, getSession } = useContext(AccountContext);

  useEffect(() => {
    if(window.location.hostname === "localhost"){
       setDev(true)
    }else{
      setDev(false);
    }
    setPath(window.location.pathname);
    if (email) {
      let user = email.split("@")[0];
      let count = user.split(".").length;
      if(count < 3) {
        setLastName(
          email.split(".")[1].charAt(0).toUpperCase() +
            email.split(".")[1].split("@")[0].slice(1)
        );
      }else{
        setLastName(
          email.split(".")[2].charAt(0).toUpperCase() +
            email.split(".")[2].split("@")[0].slice(1)
        );
      }
      setFirstName(
        email.split(".")[0].charAt(0).toUpperCase() +
          email.split(".")[0].slice(1)
      );
      
    }
  }, [email]);

  useEffect(() => {
    if(!authenticated) setPermissions("")
  }, [authenticated])

  useEffect(() => {
    
    getSession()
      .then((result) => {
        console.log(result.data);
        result.data.map((attr) => {
          if (attr.Name === "email") setEmail(attr.Value);
          if (attr.Name === "zoneinfo") setPermissions(attr.Value);
          if (attr.Name === "identities") {
            if (JSON.parse(attr.Value)[0]) {
              if (JSON.parse(attr.Value)[0]["userId"]) {
                setUsername(JSON.parse(attr.Value)[0]["userId"]);
              }
            }
          }
        });
        permissions === "administrator" ? setAuthenticated(true) : setAuthenticated(false);
      })
      .catch(() => {
        setAuthenticated(false);
      });
  }, []);

  return (
    <div className="App">
      <Router>
        {authenticated ? (
          <>
            <ReadyOneNavbar
              email={email}
              username={username}
              firstName={firstName}
              lastName={lastName}
              setAuthenticated={setAuthenticated}
              setEmail={setEmail}
              path={path}
              setPath={setPath}
              permissions={permissions}
            />
            <header className="App-header">
              <Routes>
                <Route
                  path="/"
                  element={<Dashboard setPath={setPath} />}
                />
                <Route
                  path="/Dashboard"
                  element={<Dashboard setPath={setPath} />}
                />
                <Route
                  path="/Requests"
                  element={<Requests setPath={setPath} />}
                />
                <Route
                  path="/Servers"
                  element={<Servers setPath={setPath} />}
                />
                <Route
                  path="/Feedback"
                  element={<Feedback setPath={setPath} />}
                />
              </Routes>
              <ParticlesB />
              <FooterBar
                setPath={setPath}
                email={email}
                username={username}
                firstName={firstName}
                lastName={lastName}
                setAuthenticated={setAuthenticated}
                setEmail={setEmail}
                path={path}
              />

              
            </header>
          </>
        ) : (
          <>
            <div>
              <Routes>
                <Route
                  path="*"
                  exact
                  element={
                    <Login
                      setAuthenticated={setAuthenticated}
                      setEmail={setEmail}
                      setUsername={setUsername}
                      dev={dev}
                    />
                  }
                />
              </Routes>
              <ParticlesB />
            </div>
          </>
        )}
      </Router>
    </div>
  );
}

export default App;

import React, { useState, useEffect, useContext } from "react";
import "./navbar.css";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import QuickLinksMenu from "./QuickLinksMenu";
import { QuickNavLink } from "./QuickNavLink";
import { AccountContext } from "../Authentication/Account";
import { getTable } from "../../services/ReadyOneAPI";
import saiclogo from "./images/saiclogo.png";

export const FooterBar = (props) => {
    const [quicklinks, setQuicklinks] = useState([]);
    const { getSession } = useContext(AccountContext);
    const [path, setPath] = useState("");

    const [show, setShow] = useState(false);
    
  
    useEffect(() => {
      getSession().then((result) => {
        result.data.map((attr) => {
          if (attr.Name === "email") props.setEmail(attr.Value);
        });
        props.setAuthenticated(true);
      });
  
      getTable("QuickLinks").then((result) => {
        var temp = result.body.Items;
        setQuicklinks(temp);
      });
    }, []);
  
    const getActiveKey = () => { 
        if(path) return path;
        return ["/Getting-Started", "/How-To-Resources", "/Request-Form"].includes(props.path) ?  props.path : "/Getting-Started"; 
      }
    
    return (
    <>
      <div className="footer top-shadow d-none d-lg-block" expand="lg" sticky="bottom">
        {/* <div className="footer-col-one">
        The
        </div> */}
          
        <div className="footer-col-two">
          <img className="footersaiclogo" src={saiclogo} />
        </div>

        </div>
    
    <Navbar sticky="bottom" variant="dark" className="mobile-navbar">
    <Nav
              fill
              className="mr-auto d-md-flex d-lg-none"
              defaultActiveKey="/Getting-Started"
              activeKey={getActiveKey()}
            >
              <Nav.Link
                eventKey="/Getting-Started"
                
                as={Link}
                className="white-nav-text mobile-nav-link"
                to="/Getting-Started"
              >
                <h5 className="white-nav-text">Getting Started</h5>
              </Nav.Link>
  
              <Nav.Link
                eventKey="/How-To-Resources"
                as={Link}
                className="white-nav-text mobile-nav-link"
                to="/How-To-Resources"
              >
                <h5 className="white-nav-text">How-To Resources</h5>
              </Nav.Link>
  
              <Nav.Link
                eventKey="/Request-Form"
                as={Link}
                className="white-nav-text mobile-nav-link"
                to="/Request-Form"
              >
                <h5 className="white-nav-text">Request Form</h5>
              </Nav.Link>
  
              {/* <QuickNavLink
                eventKey="/Quicklinks"
                as={Link}
                className="white-nav-text mobile-nav-link"
                // to="/Quicklinks"
                text="Quick Links" 
                setPath={setPath}
              >
               <QuickLinksMenu links={quicklinks} ></QuickLinksMenu>
              </QuickNavLink> */}
            </Nav>
            </Navbar>
    </>
  )};
  

  export default FooterBar;
import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";
import styles from "../WebPages/Dashboard/dashboard.module.css";
import { BsPersonCheck } from "react-icons/bs";

const SimpleAreaChart = () =>{
  const data = [
    {
      name: '8:00',
      Users: 4,
    },
    {
      name: '9:00',
      Users: 14,
    },
    {
      name: '10:00',
      Users: 28,
    },
    {
      name: '11:00',
      Users: 37,
    },
    {
      name: '12:00',
      Users: 41,
    },
    {
      name: '1:00',
      Users: 32
    },
    {
      name: 'Live',
      Users: 28
    }
  ];

  const CustomTooltip = ({ active, payload, label, cursor }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className={styles.tooltipLabel}><BsPersonCheck className={styles.tooltipIcon}/>{`  ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };
  
  const renderAreaChart = (

      <AreaChart width={525} height={400} data={data} margin={{ top: 20, right: 20, bottom: 10, left: 0 }}>
        <defs>
          <linearGradient id="colorUsers" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor="#016bb5" stopOpacity={1}/>
            <stop offset="80%" stopColor="#016bb5" stopOpacity={0.25}/>
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey="Users" stroke="#9a9da0" fill="url(#colorUsers)" />
        <CartesianGrid stroke="rgb(102, 102, 102)" strokeDasharray="0 0" />
        <XAxis stroke="#ccc" dataKey="name" />
        <YAxis stroke="#ccc" />
        <Tooltip cursor={{ fill: 'rgba(154, 157, 160, 0.15)'}} Tooltip content={<CustomTooltip />} allowEscapeViewBox={{ x: false, y: true }} position={{ y:0 }} />
      </AreaChart>

  );

  return (
    <div className={styles.adminDashChart} >
        { renderAreaChart }
    </div>
  );
}

export default SimpleAreaChart;
import React, { useEffect, useState } from "react";
import { BsChevronRight, BsSun } from "react-icons/bs";
import styles from "./Feedback.module.css";
import FeedbackView from "./FeedbackView";

const FeedbackTable = ({ data }) => {
  const [activeShow, setActiveShow] = useState("");
  const formatDate = (input) => {
    let date = new Date(input);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return `${month}.${dt}.${year}`;
  };

  const getDaysSinceDate = (date) => {
    date = new Date(date);
    var Difference_In_Time = new Date().getTime() - date.getTime();
    // To calculate the no. of days between two dates
    return Math.floor(Difference_In_Time / (1000 * 3600 * 24));
  };

  return (
    <>
      
        {data.length ? (
          <>
          <table className={styles.feedbackTable}>
            <thead className={styles.feedbackThead}>
              <tr>
                <th className={styles.feedbackTh}>Subject</th>
                <th className={styles.feedbackTh}>Topic</th>
                <th className={styles.feedbackTh}>Email</th>
                <th className={styles.feedbackTh}>Explanation</th>
              </tr>
            </thead>
            <tbody className={styles.feedbackTbody}>
              {data.map((r) => (
                <>
                  <tr
                    className={styles.feedbackTr}
                    key={r.feedbackID}
                    onClick={() => setActiveShow(r.feedbackID)}
                  >
                    <td className={styles.feedbackTdOne}>{r.feedback.subject}</td>
                    <td className={styles.feedbackTd}>{r.feedback.topic.value}</td>
                    <td className={styles.feedbackTd}>{r.feedback.email}</td>
                    <td className={styles.feedbackTd}>{r.feedback.explanation}</td>
                  </tr>
                </>
                // </Link>
              ))}
            </tbody>
            </table>
            {data.map((r) => (
              <FeedbackView
                feedback={r}
                activeShow={activeShow}
                deactivateShow={() => setActiveShow("")}
              />
            ))}
            
          </>
        ) : (
          <div className={styles.thCaughtUp}>
            You're all caught up
            <BsSun className={styles.theSunIsHot} />
          </div>
        )}
    </>
  );
};

export default FeedbackTable;

export function createStack(template) {

    let raw = "{ \"templateURL\": \""+ template.templateURL +"\",\n    \"stackName\" : \"" + template.appName + "\",\n    \"VPCId\" : \"" + template.VPCId + "\",\n  \"stackTag\" : \"" + template.stackTag + "\",\n  \"keyPair\" : \"" + template.keyPair + "\"}";
    console.log(raw);
    var myHeaders = new Headers();
    myHeaders.append("X-Api-Key", "rNhXOVDYNc16f0FVqTJfv4YtCbl99TwE2yHk57du");

    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    return fetch('https://1bu1gkixh3.execute-api.us-gov-west-1.amazonaws.com/default/createStack', requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
}
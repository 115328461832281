import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import TableView from "../../ServerView/components/TableView";
import { getTable } from "../../../services/ReadyOneAPI";
import styles from "./Servers.module.css";
import { BsChevronRight } from "react-icons/bs";
import Server from "./Server";
import RingLoader from "react-spinners/RingLoader";

const Servers = ({ setPath }) => {
  const [serverInfo, setServerInfo] = useState([]);
  const [activeShow, setActiveShow] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setPath(window.location.pathname);

    getTable("ServerInformation").then((data) => {
      data = data.body.Items;
      data = data.sort((a, b) =>
        a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0
      );
      data = data.sort((a, b) =>
        a.State > b.State ? 1 : a.State < b.State ? -1 : 0
      );
      setServerInfo(data);
      setLoading(false);
    });
  }, []);

  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index) =>
    activeIndex === index ? styles.panel : styles.hidden;
  const checkActiveTab = (index) =>
    activeIndex === index ? styles.activeTab : "";

  return loading ? (
    <div className="login-ring-loader">
      <RingLoader size={80} color={"#ffffff"} loading={loading} width="200px" />
    </div>
  ) : (
    <div className={styles.main}>
      <div className={styles.title}>Servers</div>
      {serverInfo.map((server) => (
        <Server
          server={server}
          activeShow={activeShow}
          deactivateShow={() => setActiveShow("")}
        />
      ))}
      <div className={styles.body}>
        <div className={styles.serverTabContainer}>
          <button
            className={`${styles.tab} ${checkActiveTab(1)}`}
            onClick={() => handleClick(1)}
          >
            Servers
          </button>

          <button
            className={`${styles.tab} ${checkActiveTab(2)}`}
            onClick={() => handleClick(2)}
          >
            Stacks
          </button>
        </div>

        <div className={styles.panels}>
          <div className={`${checkActive(1)}`}>
            <div className={styles.serverRows}>
              <div className={styles.serverBox1}>
                <div className={styles.serverBoxTitle}>Running</div>

                <div className={styles.serverColOne}>
                  {serverInfo
                    .filter((data) => data.State === "running")
                    .map((server) => (
                      <div
                        className={styles.serverRow}
                        key={server.InstanceId}
                        onClick={() => setActiveShow(server.InstanceId)}
                      >
                        {server.Name}

                        <div className={`${styles.serverInfo}`}>
                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Domain:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.DomainName}{" "}
                            </h3>
                          </div>

                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Public IP:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.PublicIpAddress}{" "}
                            </h3>
                          </div>

                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Private IP:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.PrivateIpAddress}{" "}
                            </h3>
                          </div>

                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Stack:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.Stack}{" "}
                            </h3>
                          </div>

                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Key:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.KeyName}{" "}
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className={styles.serverBox2}>
                <div className={styles.serverBoxTitle}>Stopped</div>
                <div className={styles.serverColTwo}>
                  {serverInfo
                    .filter((data) => data.State === "stopped")
                    .map((server) => (
                      <div className={styles.serverRow} key={server.InstanceId}>
                        {server.Name}

                        <div className={`${styles.serverInfo}`}>
                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Domain:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.DomainName}{" "}
                            </h3>
                          </div>

                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Public IP:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.PublicIpAddress}{" "}
                            </h3>
                          </div>

                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Private IP:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.PrivateIpAddress}{" "}
                            </h3>
                          </div>

                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Stack:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.Stack}{" "}
                            </h3>
                          </div>

                          <div className={`${styles.serverInfoCell}`}>
                            <h3 className={styles.infoCellDesc}>Key:</h3>
                            <h3 className={styles.infoCellInfo}>
                              {" "}
                              {server.KeyName}{" "}
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <></>
          </div>

          <div className={`${checkActive(2)}`}>
            <div className={styles.stacksBody}>
              <TableView styles={styles} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.stacksFooter}>
        <Row>
          <div className={styles.stacksFooterTitle}>Helpful Links</div>
        </Row>
        <Row className={styles.quickLinksCardRow}>
          <div className={styles.tableHolder}>
            <div className={styles.grid}>
              <a
                href="https://gov.teams.microsoft.us/l/file/89d79f9e-5f4c-4ada-9b39-c31b04422e27?tenantId=728ac41d-52a3-4e8c-b431-b300a7a7ee8b&fileType=xlsx&objectUrl=https%3A%2F%2Fsaiconline.sharepoint.us%2Fteams%2FDEEcosystem%2FShared%2520Documents%2FGeneral%2FCopy%2520of%2520DE%2520Ecosystem%2520Servers.xlsx&baseUrl=https%3A%2F%2Fsaiconline.sharepoint.us%2Fteams%2FDEEcosystem&serviceName=recent"
                className={styles.card}
              >
                <div className={styles.cardTitle}>
                  DE Eco Servers{" "}
                  <BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to the DE server list.</p>
              </a>

              <a
                href="https://228903717797.signin.amazonaws-us-gov.com/console"
                className={styles.card}
              >
                <div className={styles.cardTitle}>
                  AWS Gov <BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to AWS Gov login page.</p>
              </a>

              <a
                href="https://console.aws.amazon.com/console"
                className={styles.card}
              >
                <div className={styles.cardTitle}>
                  AWS <BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to AWS login page.</p>
              </a>

              <a
                href="https://twc.readyone.net:8443/webapp/"
                className={styles.card}
              >
                <div className={styles.cardTitle}>
                  TWC <BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to the TWC page.</p>
              </a>

              <a href="https://readyone.net/Reports" className={styles.card}>
                <div className={styles.cardTitle}>
                  ADO Reports <BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to ADO Reports page.</p>
              </a>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default Servers;

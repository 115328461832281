import React, { useState, useEffect, useContext, useRef } from "react";
import linkStyles from "./navbar.module.css";
import { Col } from "react-bootstrap";
import { BsChevronLeft, BsPower, BsFileEarmarkRuled } from "react-icons/bs";
import { Account, AccountContext } from "../Authentication/Account";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import { generateADOReport } from "../../services/generate-ado-report";
import { getTable } from "../../services/ReadyOneAPI";

function DropdownMenu({ props, checked }) {
  const [links, setLinks] = useState([]);
  const [authenticated, setAuthenticated] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [dropdownClass, setDropdownClass] = useState("");

  const { getSession } = useContext(AccountContext);
  const { authenticate } = useContext(AccountContext);
  const { setEmail } = useContext(AccountContext);
  const { getUserName } = useContext(AccountContext);
  const { logout } = useContext(AccountContext);
  const { firstName } = useContext(AccountContext);
  const { lastName } = useContext(AccountContext);
  const { email } = useContext(AccountContext);

  useEffect(() => {
    if (checked) {
      setDropdownClass(linkStyles.activeDropdown);
    }
  }, [checked]);

  function AccountItem(props) {
    return (
      <li className="account-menu-item">
        <Col className="account-user-info">
          <h4>{props.name}</h4>
          <h5>{email}</h5>
        </Col>
      </li>
    );
  }

  function LogoutItem(props) {
    return (
      <li
        className={linkStyles.menuitem}
        onClick={() => logout(props.setAuthenticated)}
      >
        <span className="icon-button">{props.leftIcon}</span>
        {props.children}
        <span className="icon-right">{props.rightIcon}</span>
      </li>
    );
  }

  return (
    <div className={dropdownClass + " " + linkStyles.dropdown}>
      <div className={linkStyles.menu}>
        <AccountItem name={`${firstName} ${lastName}`} />
        <LogoutItem leftIcon={<BsPower />} logout={setAuthenticated}>
          Log Off
        </LogoutItem>
      </div>
    </div>
  );
}

export default DropdownMenu;

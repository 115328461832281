export function getKeyPairs() {
    var myHeaders = new Headers();
    myHeaders.append("X-Api-Key", "ll4KVAtlFMR7LuLVr3vD7Ddza4rwgNv7d6MUxeV5");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    return fetch("https://1bu1gkixh3.execute-api.us-gov-west-1.amazonaws.com/default/getKeyPairs", requestOptions)
      .then(response => response.json())
}
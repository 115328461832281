import React, { useEffect, useState, useRef, useContext, Col } from "react";
import { AccountContext } from "../Authentication/Account";
import linkStyles from "./navbar.module.css";
import { getTable } from "../../services/ReadyOneAPI";

function QuickLinksMenu({ checked, setPath }) {
  const dropdownRef = useRef(null);
  const list = useRef(null);
  const [links, setLinks] = useState([]);
  const [dropdownClass, setDropdownClass] = useState("");
  const [loaded, setLoaded] = useState("");

  useEffect(() => {
    getTable("QuickLinks").then((result) => {
      var temp = result.body.Items;
      setLinks(temp);
    });
  }, []);

  useEffect(() => {
    if (checked) {
      setPath("/Quicklinks");
      setDropdownClass(linkStyles.activeDropdown);
    } else {
      setPath("");
    }
  }, [checked]);

  function DropdownItem(props) {
    return (
      <li
        href="#"
        className={linkStyles.quicklinksMenuItem}
        onClick={() => {
          window.open(props.url);
        }}
      >
        {props.children}
      </li>
    );
  }

  return (
    <div className={dropdownClass + " " + linkStyles.quicklinksDropdown}>
      <div className={linkStyles.quicklinksMenu}>
        {links.map((link) => (
          <DropdownItem url={link.LinkURL}>{link.LinkName}</DropdownItem>
        ))}
      </div>
    </div>
  );
}

export default QuickLinksMenu;

export async function rebootInstance(instanceId) {
    if(!instanceId) return;
    var myHeaders = new Headers();
    myHeaders.append("X-Api-Key", "usCxXaOcGx8pf4UZTlrEY9dmI7gJ7ULs1RdGxcrq");
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"instanceId": instanceId});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    return fetch("https://1bu1gkixh3.execute-api.us-gov-west-1.amazonaws.com/default/rebootInstance", requestOptions)
      .then(response => response.text())
      .then(result => {return result})
      .catch(error => console.log('error', error));

}
import React, { useState, useEffect } from "react";
import TableNavbar from "./TableNavbar";
import EmptyTableAlert from "./EmptyTableAlert";
import Loading from "./Loading";
import { Table, Col, ProgressBar } from "react-bootstrap";
import { getStatus } from "../services/getStatus";
import { getStackStatus } from "../services/getStackStatus";
import { copyToClipboard } from "../services/copyToClipboard";
import { generateKey } from "../services/generateKey";
import { deleteStack } from "../services/deleteStack";
import { rebootInstance } from "../services/rebootInstance";
import { MdRestartAlt, MdDelete } from "react-icons/md";
// import styles from "../../WebPages/Servers/Server.module.css";

const TableView = ({ styles }) => {
  const [fields, setFields] = useState([]);
  const [data, setData] = useState([]);
  const [stackFields, setStackFields] = useState([]);
  const [stackData, setStackData] = useState([]);
  const [intervalMult, setIntervalMult] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");

  useEffect(() => {
    setTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTableData();
    if (intervalMult !== 0) {
      var intID = setInterval(setTableData, intervalMult * 1000);
    }
    return () => {
      clearInterval(intID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalMult]);

  const setTableData = () => {
    getStackStatus().then((result) => {
      console.log(result);
      setStackData(result.data);
      setStackFields(
        result.fields.filter((field) => {
          return !["Step", "IP_Address", "PrivateIP", "MacID"].includes(field);
        })
      );
    });

    getStatus().then((result) => {
      console.log(result);
      setData(result.data);
      setFields(
        result.fields.filter((field) => {
          return !["Step", "IP_Address", "PrivateIP", "MacID"].includes(field);
        })
      );
    });
  };

  const clickDeleteStack = (stackName) => {
    setLoadingLabel("Deleting " + stackName);
    setLoading(true);
    Promise.all([deleteStack(stackName)]).then(() => {
      setTableData();
      setLoading(false);
    });
  };

  const clickRebootInstance = (instanceId, stackName) => {
    setLoadingLabel("Rebooting " + stackName);
    setLoading(true);
    Promise.all([rebootInstance(instanceId)]).then(() => {
      setTableData();
      setLoading(false);
    });
  };

  return (
    <div className={styles.serverChartWhole}>
      <TableNavbar
        setIntervalMult={setIntervalMult}
        setTableData={setTableData}
        styles={styles}
      ></TableNavbar>

      <Col>
        {data.length < 1 ? (
          <EmptyTableAlert />
        ) : (
          <div className={styles.serverTable}>
            <Table
              responsive
              striped
              bordered
              hover
              variant="dark"
              className={`${styles.dataTable} ${styles.tableSticky} ${styles.serverTableSticky} ${styles.serverTable}`}
            >
              <thead>
                <tr>
                  {fields.map(
                    (field, index) =>
                      field !== "Step" && (
                        <th key={generateKey(index)}>{field}</th>
                      )
                  )}
                  <th className={`${styles.copyHeader}`} key={generateKey(92)}>
                    Restart
                  </th>
                  <th className={`${styles.copyHeader}`} key={generateKey(42)}>
                    Delete
                  </th>
                </tr>
              </thead>

              <tbody>
                {data.map((d, index2) => (
                  <tr key={generateKey(index2)}>
                    {fields.map((field, index3) =>
                      field === "Status" && eval(d["Step"]) < 1 ? (
                        <td
                          key={generateKey(index3)}
                          className={`${styles.statusCell}`}
                        >
                          <ProgressBar
                            animated
                            variant={"no_u"}
                            now={eval(d["Step"]) * 100}
                            label={d[field]}
                          />
                        </td>
                      ) : field === "KeyPair" ? (
                        <td
                          key={generateKey(index3)}
                          className={`${styles.infoCell}`}
                        >
                          {(d[field] && d[field].slice(2)) || "Pending..."}
                        </td>
                      ) : field === "MacID" ? (
                        <td
                          key={generateKey(index3)}
                          className={`${styles.infoCell}`}
                        >
                          {(d[field] &&
                            d[field].slice(0, d[field].length - 1)) ||
                            "Pending..."}
                        </td>
                      ) : (
                        field !== "Step" && (
                          <td
                            key={generateKey(index3)}
                            className={`${styles.infoCell}`}
                          >
                            {d[field] || "Pending..."}
                          </td>
                        )
                      )
                    )}

                    {/*<td className="copy-cell" key={generateKey(96)} ><ImageHelper onClick={() => copyToClipboard(d["IP_Address"])} mode="copy"/></td>*/}
                    <td
                      className={`${styles.copyCell}`}
                      key={generateKey(index2 + 97)}
                    >
                      <>
                        <MdRestartAlt size={"2rem"} />
                      </>
                    </td>
                    <td
                      className={`${styles.copyCell}`}
                      key={generateKey(index2 + 6)}
                    >
                      <MdDelete size={"2rem"} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Col>
      <Loading loading={loading} label={loadingLabel} />
    </div>
  );
};

export default TableView;

import React, { useState, useEffect, useContext } from "react";
import linkStyles from "./navbar.module.css";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { VscAccount } from "react-icons/vsc";
import { AccountContext } from "../Authentication/Account";
import ImageHelper from "../ImageHelper";
import { getTable } from "../../services/ReadyOneAPI";
import { QuickNavLink } from "./QuickNavLink";
import { NavItem, SearchNavItem, TestNavItem } from "./NavItems";
import newROlogo from "./images/newROlogo.png";

const ReadyOneNavbar = (props) => {
  const [showSearch, setShowSearch] = useState(false);
  const { getSession } = useContext(AccountContext);
  const [path, setPath] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    getSession().then((result) => {
      result.data.map((attr) => {
        if (attr.Name === "email") props.setEmail(attr.Value);
      });
      props.setAuthenticated(true);
    });
  }, []);

  const getActiveKey = () => {
    if (path) return path;
    return ["/Dashboard", "/Requests", "/Servers", "/Feedback"].includes(
      props.path
    )
      ? props.path
      : "/Dashboard";
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="navbar bottom-shadow"
        sticky="top"
      >
        <Navbar.Brand className="brand">
          <img className="navbarLogoRO" src={newROlogo} />
          <div className="d-inline" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-none" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            fill
            variant="tabs"
            className="mr-auto d-none d-lg-flex"
            defaultActiveKey="/Dashboard"
            activeKey={getActiveKey()}
          >
            <Nav.Link
              eventKey="/Dashboard"
              as={Link}
              className="white-nav-text"
              to="/Dashboard"
            >
              <h5>Dashboard</h5>
            </Nav.Link>

            <Nav.Link
              eventKey="/Requests"
              as={Link}
              className="white-nav-text"
              to="/Requests"
            >
              <h5>Requests</h5>
            </Nav.Link>

            <Nav.Link
              eventKey="/Servers"
              as={Link}
              className="white-nav-text"
              to="/Servers"
            >
              <h5>Servers</h5>
            </Nav.Link>

            <Nav.Link
              eventKey="/Feedback"
              as={Link}
              className="white-nav-text"
              to="/Feedback"
            >
              <h5>Feedback</h5>
            </Nav.Link>

            <QuickNavLink
              eventKey="/Quicklinks"
              as={Link}
              path={path}
              setPath={setPath}
            ></QuickNavLink>
          </Nav>
        </Navbar.Collapse>
        <h5 className="white-nav-text account-hello d-none d-lg-flex">{`Welcome, ${props.firstName}!`}</h5>
        <div className="nav-container navbar-nav">
          <SearchNavItem
            icon={<BsSearch />}
            setShow={setShowSearch}
            show={showSearch}
          />
          <TestNavItem
            className="acct-menu"
            icon={<VscAccount size={"77px"} />}
            email={props.email}
            firstName={props.firstName}
            lastName={props.lastName}
            setAuthenticated={props.setAuthenticated}
            permissions={props.permissions}
          >
            {/* <DropdownMenu
              email={props.email}
              firstName={props.firstName}
              lastName={props.lastName}
              setAuthenticated={props.setAuthenticated}
              permissions={props.permissions}
            /> */}
          </TestNavItem>
        </div>
      </Navbar>
      {/* <SpotlightSearch show={showSearch} showControl={setShowSearch}/> */}
    </>
  );
};

export default ReadyOneNavbar;

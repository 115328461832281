import styles from "./dashboard.module.css";
import React, { useState, useEffect, useContext } from "react";
import { BsChevronRight, BsSun } from "react-icons/bs";
import { Container, Row, Col, Table } from "react-bootstrap";
import SimpleLineChart from "../../Charts/lineChart";
import SimpleAreaChart from "../../Charts/areaChart";
import SimpleCircleChart from "../../Charts/circleChart";
import SimpleBarChart from "../../Charts/barChart";
import { getTable } from "../../../services/ReadyOneAPI";
import { getMonthlyCosts } from "../../../services/ReadyOneAdminAPI";


export default function Home({ setPath }) {
  const [serverData, setServerData] = useState([]);
  const [costData, setCostData] = useState([]);
  useEffect(() => {
    setPath(window.location.pathname);
    getTable("ServerInformation").then((data) => {
        data = data.body.Items;
    data = data.sort((a,b) => (a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0) )
    data = data.sort((a,b) => (a.State > b.State ? 1 : a.State < b.State ? -1 : 0) )
    console.log(data);
    let running = data.filter(server => server.State === 'running').length;
    let stopped = data.filter(server => server.State === 'stopped').length;

    setServerData([
      {
        name: "Server Running",
        value: running,
      },
      {
        name: "Server Stopped",
        value: stopped,
      }
    ])
    })

    getMonthlyCosts().then((result) => {
      console.log(result)
      setCostData(result)
    })

    
  }, [])
  

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>Dashboard</h1>
      <div className={styles.dashboardBody}>
        <div className={styles.dashboardBodyContainer}>
        
          <div className={styles.dashboardColOne}>
            <div className={styles.graphColumnOne}>
              <div className={styles.graphHeader}>Servers Running</div>
              <div className={styles.graphContainer}>
                <SimpleCircleChart data={serverData} />
              </div>

              <div className={styles.graphHeader}>Current Users</div>
              <div className={styles.graphContainer}>
                <SimpleAreaChart />
              </div>
            </div>
          </div>

          <div className={styles.dashboardColTwo}>
            <div className={styles.graphColumnTwo}>
              <div className={styles.graphHeaderMain}>
                ReadyOne Admin
              </div>

                <div className={styles.colTwoContainer}>
                  <div className={styles.colTwoHead}>
                    Welcome to the Admin page. Here, you will find all the tools
                    neccessary to manage ReadyOne and all the services it offers.
                    Here is an overview of each page to help get you started.
                  </div>
                  <br />

                  <div className={styles.colTwoTitle}>
                    <div className={styles.colTwoSubhead}>
                      Requests
                    </div>
                  </div>
                  <div className={styles.colTwoBodyText}>
                      Here you will find user created requests for various account types.
                      You can manage, approve, deny and comment on each request by clicking
                      on any user request.
                  </div>
                  <br />

                  <div className={styles.colTwoTitle}>
                    <div className={styles.colTwoSubhead}>
                      Servers
                    </div>
                  </div>
                  <div className={styles.colTwoBodyText}>
                      Here you will running and stopped server, where you can manage them
                      by clicking on them. You can also view current stacks, where you will
                      see buttons at the top to manage them as well.
                  </div>
                  <br />

                  <div className={styles.colTwoTitle}>
                    <div className={styles.colTwoSubhead}>
                      Feedback
                    </div>
                  </div>
                  <div className={styles.colTwoBodyText}>
                      Here you will find user created feedback for ReadyOne. Users are able
                      to submit bugs, feedback, suggestions, or as for help. You can manage
                      and respond to the feedback by clicking on them.
                  </div>

                </div>
           
            </div>
          </div>

          <div className={styles.dashboardColThree}>
            <div className={styles.graphColumnThree}>
              <div className={styles.graphHeader}>AWS Costs</div>
              <div className={styles.graphContainer}>
                <SimpleLineChart data={costData} /> 
              </div>

              <div className={styles.graphHeader}>New Users</div>
              <div className={styles.graphContainer}>
                <SimpleBarChart /> 
              </div>   
            </div>
          </div>
        </div>
      </div>
      <div className={styles.underGraphDash}>
        <Row>
            <div className={styles.underGraphHeader}>Quick Links</div>
        </Row>
        <Row className={styles.quickLinksCardRow}>
          <div className={styles.tableHolder}>
            <div className={styles.grid}>
              <div href="https://readyone.net" className={styles.card}>
                <div className={styles.cardTitle}>
                  ReadyOne<BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to ReadyOne.</p>
              </div>

              <a
                href="https://228903717797.signin.amazonaws-us-gov.com/console"
                className={styles.card}
              >
                <div className={styles.cardTitle}>
                  AWS Gov<BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to AWS Gov login page.</p>
              </a>

              <a href="https://console.aws.amazon.com/console" className={styles.card}>
                <div className={styles.cardTitle}>
                  AWS<BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to AWS login page.</p>
              </a>

              <a href="https://readyone.net/Reports" className={styles.card}>
                <div className={styles.cardTitle}>
                  ADO Report<BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to ADO Reports.</p>
              </a>

              <a href="https://twc.readyone.net:8443/webapp/" className={styles.card}>
                <div className={styles.cardTitle}>
                  TWC<BsChevronRight className={styles.chevronRight} />
                </div>
                <p>Click here to navigate to the TWC page.</p>
              </a>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}
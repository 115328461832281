import React, { useState, useEffect} from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { getKeyPairs } from '../services/getKeyPairs';
import { getVPCIds } from '../services/getVPCIds';

const SelectModal = props => {
    const [keyPairs, setKeyPairs] = useState([]);
    const [VPCIds, setVPCIds] = useState([]);
    const [tag, setTag] = useState("");

    useEffect(() => {
        getKeyPairs().then((result) => {
            setKeyPairs(result);
        });
        getVPCIds().then((result) => {
          setVPCIds(result);
        });
      }, []);
  
      const setTags = (t) => {
        props.setStackTag(t)
        t !== "" ? setTag("-" + t) : setTag("");
      }

  return (
    <Modal  show={props.show} onHide={props.secondButton}>
      <Modal.Header className={props.mode + '-modal-header'}>
        <Modal.Title className="confirm-modal-header">
          {props.title + tag}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={props.mode + '-modal-body'}>
        <Form.Group as={Col} controlId="vpcIdsForm">
          <Form.Label className="text-white">VPC</Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            value={props.VPCId}
            onChange={(e) => props.setVPCId(e.target.value)}
           >
           {
               VPCIds.map((k) => (
                  <option key={k.VPCId} value={k.VPCId}>{k.name}</option>
               ))
            }
          </Form.Control>
        </Form.Group>
        <br />
        <Form.Group as={Col} controlId="keyPairForm">
          <Form.Label className="text-white">Key Pair</Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            value={props.keyPair}
            onChange={(e) => props.setKeyPair(e.target.value)}
           >
           {
               keyPairs.map((k) => (
                  <option key={k} value={k}>{k}</option>
               ))
            }
          </Form.Control>
        </Form.Group>
        <br />
        <Form.Group as={Col} controlId="keyPairForm">
          <Form.Label className="text-white">Stack Tag</Form.Label>
          <Form.Control type="text" placeholder="Optional (Leave Blank for Random Tag)" value={props.stackTag} onChange={(e) => setTags(e.target.value)}/>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer className={props.mode + '-modal-footer'}>
        <Button
          onClick={props.firstButton}
          variant="primary"
          className="modal-button"
        >
          Create Stack
        </Button>
        <Button
          onClick={() => props.secondButton(false)}
          variant="danger"
          className="modal-button"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectModal;
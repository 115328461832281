export function getStatus() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", "rNhXOVDYNc16f0FVqTJfv4YtCbl99TwE2yHk57du");
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  return fetch("https://1bu1gkixh3.execute-api.us-gov-west-1.amazonaws.com/default/getAppStatus", requestOptions)
    .then(response => response.json())
}
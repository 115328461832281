import React, { useState, useEffect, useRef, useContext } from "react";
import "./navbar.css";
import linkStyles from "./navbar.module.css";
import DropdownMenu from "./DropdownMenu";

export function TestNavItem(props) {
  const [checked, setChecked] = useState(false);
  const innerRef = useOuterClick(() => {
    setChecked(false);
  });

  return (
    <li
      className="nav-item acct-nav-item"
      ref={innerRef}
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <input
        type="checkbox"
        checked={checked}
        className={linkStyles.menuToggler}
      />
      <label href="#" className="icon-button">
        {props.icon}
      </label>
      <DropdownMenu
        checked={checked}
        email={props.email}
        firstName={props.firstName}
        lastName={props.lastName}
        setAuthenticated={props.setAuthenticated}
        permissions={props.permissions}
      />
    </li>
  );
}

export function NavLink(props) {
  const [open, setOpen] = useState(false);

  return (
    <li className="nav-link">
      <a onClick={() => setOpen(!open)}>
        <h5>{props.text}</h5>
      </a>

      {open && props.children}
    </li>
  );
}

export function SearchNavItem(props) {
  return (
    <li className="nav-item acct-nav-item">
      <a
        href="#"
        className="icon-button"
        onClick={() => props.setShow(!props.show)}
      >
        {props.icon}
      </a>
    </li>
  );
}

function useOuterClick(callback) {
  const callbackRef = useRef(); // initialize mutable ref, which stores callback
  const innerRef = useRef(); // returned to client, who marks "border" element

  // update cb on each render, so second useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}

import styles from "./Requests.module.css";
import React, { useState, useEffect } from "react";
import RequestTable from "./RequestTable";
import { getTable } from "../../../services/ReadyOneAPI";
import RingLoader from "react-spinners/RingLoader";

const Requests = ({ setPath }) => {
  const requestCategory = [
    { label: "New User", value: "new-user" },
    { label: "Teamwork Cloud", value: "twc" },
    { label: "IBM ELM", value: "elm" },
    { label: "Innovator", value: "innovator" },
  ];

  const [activeIndex, setActiveIndex] = useState(1);
  const [openList, setOpenList] = useState([]);
  const [doneList, setDoneList] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index) =>
    activeIndex === index ? styles.panel : styles.hidden;
  const checkActiveTab = (index) =>
    activeIndex === index ? styles.activeTab : "";

  useEffect(() => {
    setPath(window.location.pathname);
    setLoading(true);
    getTable("AdminRequests").then((data) => {
      data = data.body.Items;
      data = data.map((d) => ({ ...d, request: JSON.parse(d.request) }));
      data = data.map((d) => ({
        id: d.id,
        request: {
          ...d.request,
          requestHTML: Buffer.from(d.request.requestHTML, "base64").toString(
            "ascii"
          ),
        },
      }));

      setOpenList(
        data.filter((r) => {
          return (
            r.request.status === "In Progress" || r.request.status === "New"
          );
        })
      );
      setDoneList(
        data.filter((r) => {
          return r.request.status === "Done";
        })
      );

      setLoading(false);
    });
  }, []);

  return loading ? (
    <div className="login-ring-loader">
      <RingLoader size={80} color={"#ffffff"} loading={loading} width="200px" />
    </div>
  ) : (
    <div className={styles.main}>
      <div className={styles.titleUserRequests}>User Requests</div>

      <div className={styles.tabsUserRequests}>
        <div className={styles.fbTabContainer}>
          <button
            className={`tab ${checkActiveTab(1)}`}
            onClick={() => handleClick(1)}
          >
            Open
          </button>

          <button
            className={`tab ${checkActiveTab(2)}`}
            onClick={() => handleClick(2)}
          >
            Closed
          </button>
        </div>

        <div className={styles.panels}>
          <div className={`${checkActive(1)}`}>
            {requestCategory.map((cat) => (
              <div className={styles.reqCardCont} key={cat.value}>
                <div className={styles.fbStatsCard}>
                  <div className={styles.fbStatsHeader}>{cat.label}</div>
                  <div className={styles.fbStatistics}>
                    <RequestTable
                      data={openList}
                      filter={cat.value}
                      label={cat.label}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={`${checkActive(2)}`}>
            {requestCategory.map((cat) => (
              <div className={styles.reqCardCont} key={cat.value}>
                <div className={styles.fbStatsCard}>
                  <div className={styles.fbStatsHeader}>{cat.label}</div>
                  <div className={styles.fbStatistics}>
                    <RequestTable
                      data={doneList}
                      filter={cat.value}
                      label={cat.label}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requests;

import React, { useState, useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import RefreshSwitch from "./RefreshSwitch";
import SelectModal from "./SelectModal";
import Loading from "./Loading";
import { createStack } from "../services/createStack";
import { getTemplates } from "../services/getTemplates";

const TableNavbar = ({setIntervalMult, setTableData, styles}) => {
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState("");
  const [keyPair, setKeyPair] = useState("");
  const [VPCId, setVPCId] = useState("");
  const [stackTag, setStackTag] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showEcoModal, setShowEcoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");

  useEffect(() => {
    getTemplates().then((result) => {
      console.log(result);
      setTemplates(result.templates);
    });
  }, []);

  const clickTemplate = (t) => {
    let selectedTemplate = {};
    templates.map(temp => {
      if(temp.appName === t) selectedTemplate = temp;
    })
    setShowModal(true);
    setTemplate(selectedTemplate);
    console.log(selectedTemplate);
  };

  const clickCreateStack = () => {
    setLoadingLabel("Creating " + template.appName + "-" + stackTag);
    setShowModal(false);
    setLoading(true);
    var temp = template;
    temp.keyPair = keyPair;
    temp.stackTag = stackTag;
    temp.VPCId = VPCId;
    setTemplate(temp);
    createStack(template).then(() => {
      setTableData();
      setLoading(false);
    });
  };

  const clickCreateEcosystem = () => {
    setLoadingLabel("Creating DE Core");
    setShowEcoModal(false);
    setLoading(true);
    templates.map((t) => {
      t.keyPair = keyPair;
      t.stackTag = stackTag;
      t.VPCId = VPCId;
      createStack(t).then(() => {
        setTableData();
        setLoading(false);
      });
      return true;
    });
  };

  return (
    <div className={styles.navBarStackWhole}>
      <Navbar
        collapseOnSelect
        className={styles.serverNavBar}
      >
        <Navbar.Toggle />
        <Navbar.Collapse className={styles.serverNavBarCollapse}>
          <Nav className={styles.serverNavBarNav}>
            <Nav.Link
              href="https://228903717797.signin.amazonaws-us-gov.com/console"
              target="_blank"
              className={`${styles.infoCell}`}
            >
              AWS Console
            </Nav.Link>
            <Nav.Link
              href="http://access.digeng.net"
              target="_blank"
              className={`${styles.infoCell}`}
            >
              Guacamole
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setShowEcoModal(true);
              }}
              className={`${styles.infoCell}`}
            >
              Create DE Core
            </Nav.Link>
            <select className={styles.stackDropdown} onChange={(event) => clickTemplate(event.target.value)}>
            <option value="" disabled selected>Create Stack</option>
              {templates.map((t) => (
                <option key={t.appName} className={styles.stackDropdownOption} >
                  {t.appName}
                </option>
              ))}</select>
          </Nav>
          
          <Nav className={`${styles.refreshNav}`}>
            <RefreshSwitch setIntervalMult={setIntervalMult} />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <SelectModal
        show={showModal}
        title={template.appName}
        mode="confirm"
        firstButton={() => clickCreateStack()}
        secondButton={setShowModal}
        setKeyPair={setKeyPair}
        setVPCId={setVPCId}
        setStackTag={setStackTag}
        VPCId={VPCId}
        keyPair={keyPair}
        stackTag={stackTag}
      ></SelectModal>
      <SelectModal
        show={showEcoModal}
        title="Create Ecosystem"
        mode="confirm"
        firstButton={() => clickCreateEcosystem()}
        secondButton={setShowEcoModal}
        setKeyPair={setKeyPair}
        setVPCId={setVPCId}
        setStackTag={setStackTag}
        VPCId={VPCId}
        keyPair={keyPair}
        stackTag={stackTag}
      ></SelectModal>
      <Loading loading={loading} label={loadingLabel} />
    </div>
  );
};

export default TableNavbar;

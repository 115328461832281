import React, { useState, useEffect, useRef } from "react";
import linkStyles from "./navbar.module.css";
import { getTable } from "../../services/ReadyOneAPI";
import QuickLinksMenu from "./QuickLinksMenu";

export function QuickNavLink(props) {
  const [checked, setChecked] = useState(false);
  const innerRef = useOuterClick(() => {
    setChecked(false);
  });

  return (
    <li
      className={linkStyles.quicklinksTab}
      ref={innerRef}
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <input
        type="checkbox"
        checked={checked}
        className={linkStyles.menuTogglerLinks}
      />
      <label href="#" className={linkStyles.linkIconBtn}>
        Quick Links
      </label>
      <QuickLinksMenu checked={checked} setPath={props.setPath} />
    </li>
  );
}

export function useOuterClick(callback) {
  const callbackRef = useRef();
  const innerRef = useRef();
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    }
  }, []);

  return innerRef;
}

import React, { useState } from "react";
import { ButtonGroup, ToggleButton, Col, Row } from "react-bootstrap";
import { MdRefresh } from 'react-icons/md'

const RefreshSwitch = (props) => {
  const [radioValue, setRadioValue] = useState(1);

  const setRefresh = (val) => {
    console.log(val);
    if (val > 4) val = 1;
    setRadioValue(val);
    props.setIntervalMult(radios[val - 1].mult);
  };

  const radios = [
    { name: "Off", value: 1, mult: 0 },
    { name: "5 Sec", value: 2, mult: 5 },
    { name: "30 Sec", value: 3, mult: 30 },
    { name: "2 Min", value: 4, mult: 120 },
  ];

  return (
    <div className="refresh-switch">
      <div className="px-2 mt-1 refresh-switch-child d-none d-lg-inline">
        <MdRefresh size="2rem" color="white" />
      </div>
      <ButtonGroup className="refresh-switch-child">
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={"outline-secondary"}
            name={radio.name}
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={() => setRefresh(radio.value)}
            className="refresh-switch-child"
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default RefreshSwitch;
